import React from "react"
import styled from "styled-components"

import { Loader, Card, ProfileImage } from "../../../components"
import { useUser } from "../../hooks"

const ContractSignee = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;

  .image-container {
    display: flex;
    height: 50px;
    min-width: 50px;
    max-width: 50px;
    background-color: ${({ theme }) => theme.palette.mystic};
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.palette.white};

    img {
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    align-items: center;
  }
`

const ContractCardWrapper = styled.div`
  flex: 1;
  width: 100%;

  div.card-contents {
    display: grid;
    position: relative;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 15px;

    .card-heading {
      margin-bottom: 0;
    }

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: 32px minmax(min-content, max-content) 1fr;
      grid-template-areas:
        "card-icon heading heading"
        ". card-action signee";
      padding: 10px;

      .card-icon {
        grid-area: card-icon;
      }

      .card-heading {
        grid-area: heading;
        width: 150px;
      }

      .card-action {
        grid-area: card-action;
      }

      ${ContractSignee} {
        grid-area: signee;
      }
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.md}) and (max-width: ${({ theme }) =>
        theme.breakpoints.lg}) {
      grid-template-areas:
        "card-icon heading heading"
        "card-action card-action signee";
      padding: 0px;
    }
  }

  h3 {
    color: ${({ theme }) => theme.palette.white};
    font-family: ${({ theme }) => theme.fonts.title};
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  button,
  .sign-complete {
    background-color: transparent;
    border-radius: 18px;
    border: 2px solid ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.white};
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    padding: 10px 15px;
    transition: 0.2s;
    min-width: 70px;
  }

  button {
    &:disabled {
      opacity: 0.5;

      :hover {
        cursor: default;
        background-color: transparent;
        color: ${({ theme }) => theme.palette.white};
      }
    }

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.palette.white};
      color: ${({ theme }) => theme.palette.blue};
    }
  }

  .sign-complete {
    position: relative;
    padding-right: 45px;

    .sign-complete-icon {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

type Props = {
  heading: string
  disabled: boolean
  loading: boolean
  signed?: boolean
}

const ContractCard: React.FC<Props> = ({
  heading,
  disabled,
  loading,
  signed = false,
}) => {
  const { user } = useUser()

  return (
    <ContractCardWrapper>
      <Card
        padding="20px"
        backgroundColor="#00A9E0"
        hasShadow={!signed}
        borderRadius="12px"
      >
        <div className="card-contents">
          <img
            src="/images/dashboard/icon-signature.svg"
            className="card-icon"
            alt="Signature icon"
            width="50"
            height="50"
          />
          <h3 className="card-heading">{heading}</h3>
          <div className="card-action">
            {loading ? (
              <Loader color="#FFFFFF" />
            ) : signed ? (
              <div className="sign-complete">
                <span>Contract Signed</span>
                <svg width={18} height={18} className="sign-complete-icon">
                  <use href="/images/icons/icon-circled-check.svg#base" />
                </svg>
              </div>
            ) : (
              <button type="submit" disabled={disabled}>
                View &amp; sign now
              </button>
            )}
          </div>
          <ContractSignee>
            {signed ? (
              <div className="image-container">
                <ProfileImage
                  src={
                    !loading && user?.profile?.profilePicture
                      ? user.profile.profilePicture
                      : "/images/icons/icon-default-profile.svg"
                  }
                  alt={
                    user ? `${user.firstName}'s user icon` : "Default user icon"
                  }
                />
              </div>
            ) : null}
            <div>
              {signed ? (
                <>
                  <h4>
                    {user?.firstName} {user?.lastName}
                  </h4>
                  <p>Official Yoco Reseller</p>
                </>
              ) : (
                <p>
                  Unsigned by {user?.firstName} {user?.lastName}
                </p>
              )}
            </div>
          </ContractSignee>
        </div>
      </Card>
    </ContractCardWrapper>
  )
}
export default ContractCard
