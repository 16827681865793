import { Formik } from "formik"
import React, { useCallback, useMemo } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { Input, Button, FormGrid } from "../../../components"
import { useUser, useUpdateBankingDetails } from "../../hooks"
import BankAccountTypeField from "./BankAccountTypeField"
import BankNameField from "./BankNameField"
import FormHeader from "./FormHeader"

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  .buttonContainer {
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 50%;
    }
  }
`

const validationSchema = Yup.object({
  bankName: Yup.string().required("Please select your bank"),
  accountNumber: Yup.string()
    .required("Account number required")
    .matches(/^\d+$/, "Your account number must only contain digits"),
  accountType: Yup.string().required("Please select your account type"),
})

interface Props {
  onComplete?: () => void
}

const BankingDetailsForm: React.FC<Props> = ({ onComplete }) => {
  const updateBankingDetails = useUpdateBankingDetails()
  const { user } = useUser()

  const initialFormValues = useMemo(() => {
    return {
      id: user?.bankingDetails?.id,
      bankName: user?.bankingDetails?.bankName ?? "",
      branchCode: user?.bankingDetails?.branchCode ?? "",
      accountNumber: user?.bankingDetails?.accountNumber ?? "",
      accountType: user?.bankingDetails?.accountType ?? "",
    }
  }, [user])

  const onSubmit = useCallback(
    async (values: Reseller.BankDetails, { setSubmitting }) => {
      try {
        const success = await updateBankingDetails(values)
        setSubmitting(false)
        if (onComplete && success) {
          onComplete()
        }
      } catch (err) {
        console.warn(err)
      }
    },
    [updateBankingDetails, onComplete]
  )

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <FormHeader showCompletionIcon complete={!!user?.bankingDetails}>
              Banking Details
            </FormHeader>
            <FormGrid numRows={2}>
              <BankNameField />
              <Input
                placeholder="632005"
                name="branchCode"
                label="Branch Code"
                disabled={true}
              />
              <Input
                placeholder="0000000000000"
                name="accountNumber"
                label="Account Number *"
              />
              <BankAccountTypeField />
            </FormGrid>
          </div>
          <div className="buttonContainer">
            {dirty || !user?.profile?.isProfileActivated ? (
              <Button
                label="Save Changes"
                disabled={isSubmitting || !isValid || !dirty}
                loading={isSubmitting}
                type="submit"
                color="#00a9e0"
                textColor="white"
              />
            ) : (
              <Button
                label="Skip"
                disabled={isSubmitting}
                loading={isSubmitting}
                color="#e1eae8"
                onClick={onComplete}
                textColor="#222222"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default BankingDetailsForm
