import { Formik } from "formik"
import React, { useCallback, useEffect, useMemo } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import {
  Button,
  FormGrid,
  Input,
  RadioToggle,
  RadioToggleField,
} from "../../../components"
import { formatMobileNumber } from "../../../utils/mobileNumber"
import {
  useUser,
  useUpdateAddress,
  useUpdateBusinessType,
  useGetPrincipalBusiness,
} from "../../hooks"
import {
  validatePhone,
  validateAddress,
  validateBusinessRegistration,
  validateVatNumber,
} from "../../validationObjects"
import CountriesField from "./CountriesField"
import FormHeader from "./FormHeader"
import ProvincesField from "./ProvincesField"

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  .buttonContainer {
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 50%;
    }
  }

  .fullWidthInput {
    grid-column: span 1;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-column: span 2;
    }
  }

  .toggle-label {
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .radioToggleContainer {
    display: flex;
    align-items: center;
  }

  .field-header {
    text-align: center;
    font-weight: 500;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      text-align: left;
    }
  }
`

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name required"),
  lastName: Yup.string().required("Last name required"),
  companyName: Yup.string(),
  phoneNumber: validatePhone,
  vatNumber: validateVatNumber,
  businessType: Yup.string(),
  companyRegistrationNumber: Yup.string().when("businessType", {
    is: (businessType: string) => businessType === "CK_REGISTERED",
    then: validateBusinessRegistration,
    otherwise: Yup.string(),
  }),
}).concat(validateAddress)

interface Props {
  onComplete?: () => void
}

const BillingAddressForm: React.FC<Props> = ({ onComplete }) => {
  const updateBillingAddress = useUpdateAddress()
  const updateBusinessType = useUpdateBusinessType()

  const { user } = useUser()
  const billingAddress = user?.address?.find(
    (address) => address.type === "INVOICING"
  )
  const shippingAddress = user?.address?.find(
    (address) => address.type === "SHIPPING"
  )
  const [getPrincipalBusiness, data, loading] = useGetPrincipalBusiness()

  useEffect(() => {
    if (user?.profile?.profileType === "PRINCIPAL") {
      getPrincipalBusiness()
    }
  }, [user?.profile?.profileType, getPrincipalBusiness])

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        const { businessType, companyRegistrationNumber, ...addressValues } =
          values
        await updateBillingAddress(addressValues).then((addressSuccess) => {
          if (addressSuccess) {
            if (user?.profile?.profileType === "PRINCIPAL") {
              updateBusinessType({
                type: businessType,
                companyRegistrationNumber: companyRegistrationNumber,
              }).then((business) => {
                if (!business) {
                  throw "business returned null error"
                }
              })
            }
          } else {
            throw "addressSuccess returned as false error"
          }
        })
      } catch (err) {
        console.warn(err)
      } finally {
        setSubmitting(false)
        if (onComplete) {
          onComplete()
        }
      }
    },
    [
      updateBillingAddress,
      updateBusinessType,
      onComplete,
      user?.profile?.profileType,
    ]
  )

  const toggleFormOptions = [
    {
      id: "sameAsShipping",
      label: "Yes",
      value: "sameAsShipping",
      defaultChecked: false,
    },
    {
      id: "notSameAsShipping",
      label: "No",
      value: "notSameAsShipping",
      defaultChecked: true,
    },
  ]

  const initialValues = useMemo(() => {
    return {
      id: billingAddress?.id ?? undefined,
      firstName: billingAddress?.firstName ?? "",
      lastName: billingAddress?.lastName ?? "",
      companyName: billingAddress?.companyName ?? "",
      phoneNumber: billingAddress
        ? formatMobileNumber(billingAddress.phoneNumber).substring(1)
        : "",
      addressLine1: billingAddress?.addressLine1 ?? "",
      addressLine2: billingAddress?.addressLine2 ?? "",
      city: billingAddress?.city ?? "",
      province: billingAddress?.province ?? "",
      country: billingAddress?.country ?? "",
      postalCode: billingAddress?.postalCode ?? "",
      vatNumber: billingAddress?.vatNumber ?? "",
      businessType: !loading && data ? data.type : "",
      companyRegistrationNumber:
        !loading && data ? data.companyRegistrationNumber : "",
      type: "INVOICING",
    }
  }, [billingAddress, data, loading])

  const toggleBusinessTypeOptions = useMemo(
    () => [
      {
        id: "soleProprietorship",
        label: "Sole proprietorship",
        value: "SOLE_PROPRIETORSHIP",
        defaultChecked: !loading && data?.type === "SOLE_PROPRIETORSHIP",
      },
      {
        id: "ckRegistered",
        label: "CK registered business",
        value: "CK_REGISTERED",
        defaultChecked: !loading && data?.type === "CK_REGISTERED",
      },
    ],
    [loading, data]
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        setValues,
        setFieldValue,
        setFieldTouched,
        isValid,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <FormHeader showCompletionIcon complete={!!billingAddress}>
              Billing Address
            </FormHeader>
            <FormGrid numRows={7}>
              <div className="fullWidthInput radioToggleContainer">
                <p className="toggle-label">Same as shipping address?</p>
                <RadioToggle
                  name="sameAsShippingAddressToggle"
                  options={toggleFormOptions}
                  size="small"
                  OnRadioToggle={(e) => {
                    if (
                      shippingAddress &&
                      e.target?.value === "sameAsShipping"
                    ) {
                      setValues({
                        id: undefined,
                        firstName: shippingAddress?.firstName,
                        lastName: shippingAddress?.lastName,
                        companyName: shippingAddress?.companyName ?? "",
                        phoneNumber: shippingAddress?.phoneNumber,
                        addressLine1: shippingAddress?.addressLine1,
                        addressLine2: shippingAddress?.addressLine2,
                        city: shippingAddress?.city,
                        province: shippingAddress?.province,
                        country: shippingAddress?.country,
                        postalCode: shippingAddress?.postalCode,
                        vatNumber: billingAddress?.vatNumber ?? "",
                        businessType: !loading && data ? data.type : "",
                        companyRegistrationNumber:
                          !loading && data
                            ? data.companyRegistrationNumber
                            : "",
                        type: "INVOICING",
                      })
                    } else {
                      setValues(initialValues)
                    }
                  }}
                />
              </div>
              <Input
                label="First Name *"
                placeholder="First Name"
                name="firstName"
              />
              <Input
                label="Last Name *"
                placeholder="Last Name"
                name="lastName"
              />
              <Input label="Company" placeholder="Company" name="companyName" />
              <Input
                label="Phone Number *"
                placeholder="Phone Number"
                name="phoneNumber"
                additionalLabel="+27"
              />
              <Input
                label="Number and Street Address *"
                placeholder="Number and Street Address"
                name="addressLine1"
              />
              <Input label="Suburb" placeholder="Suburb" name="addressLine2" />
              <Input label="City *" placeholder="City" name="city" />
              <CountriesField />
              <ProvincesField selectedCountry={values.country} />
              <Input
                label="Postal Code *"
                placeholder="Postal Code"
                name="postalCode"
              />
              <div className="fullWidthInput">
                <Input
                  label="VAT Number"
                  placeholder="VAT Number"
                  name="vatNumber"
                />
              </div>
              {user?.profile?.profileType === "PRINCIPAL" &&
              !loading &&
              data ? (
                <>
                  <div className="fullWidthInput">
                    <p className="field-header">
                      Select your type of business:
                    </p>
                    <RadioToggleField
                      name="businessType"
                      options={toggleBusinessTypeOptions}
                      size="small"
                      OnRadioToggle={() => {
                        if (values.businessType === "CK_REGISTERED") {
                          setFieldValue("companyRegistrationNumber", "", false)
                          setFieldTouched("companyRegistrationNumber", false)
                        }
                      }}
                    />
                  </div>
                  <div className="fullWidthInput">
                    <Input
                      label={`Company Registration Number${
                        values.businessType !== "CK_REGISTERED" ? "" : " *"
                      }`}
                      placeholder={
                        values.businessType !== "CK_REGISTERED"
                          ? "Not required for this business type"
                          : "Enter Company Registration Number"
                      }
                      name="companyRegistrationNumber"
                      disabled={values.businessType !== "CK_REGISTERED"}
                    />
                  </div>
                </>
              ) : null}
            </FormGrid>
          </div>
          <div className="buttonContainer">
            {dirty || !user?.profile?.isProfileActivated ? (
              <Button
                label="Save Changes"
                disabled={isSubmitting || !isValid || !dirty}
                loading={isSubmitting}
                type="submit"
                color="#00a9e0"
                textColor="white"
              />
            ) : (
              <Button
                label="Skip"
                disabled={isSubmitting}
                loading={isSubmitting}
                color="#e1eae8"
                onClick={onComplete}
                textColor="#222222"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default BillingAddressForm
