import React, { useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { useIsMobile } from "../../../hooks"
import BankingDetailsForm from "../../auth/components/profile/BankingDetailsForm"
import BillingAddressForm from "../../auth/components/profile/BillingAddressForm"
import CurrentRanking from "../../auth/components/profile/CurrentRanking"
import IdentityVerificationForm from "../../auth/components/profile/IdentityVerificationForm"
import PasswordForm from "../../auth/components/profile/PasswordForm"
import PreferencesForm from "../../auth/components/profile/PreferencesForm"
import ProfileForm from "../../auth/components/profile/ProfileForm"
import ResellerContractForm from "../../auth/components/profile/ResellerContractForm"
import ShippingAddressForm from "../../auth/components/profile/ShippingAddressForm"
import { useUser } from "../../auth/hooks"
import { PageHeader, PageGuard, PageNotificationBanner } from "../../components"
import NavigationLayout from "../../components/NavigationLayout"
import Seo from "../../components/seo"

const Header = styled.div`
  margin-bottom: 20px;
`

const BreadcrumbNav = styled.div`
  display: block;
  h1 {
    color: ${({ theme }) => theme.palette.mineShaft};
    letter-spacing: -0.2px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 13px;
    font-weight: 500;
    margin: 20px 0px;
    span {
      padding-right: 10px;
    }
    span:first-child {
      cursor: pointer;
    }
    span.previousPage {
      color: ${({ theme }) => theme.palette.doveGrey};
    }
  }
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`

const DesktopHeader = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

const enum PROFILE_PAGES {
  CurrentRanking,
  EditProfile,
  VerifyIdentity,
  Password,
  BankingDetails,
  ShippingAddress,
  BillingAddress,
  Preferences,
  ResellerContract,
}

const ProfilePage = (): React.ReactElement => {
  const isMobile = useIsMobile()
  const { user } = useUser()

  const userShippingAddress = user?.address?.find(
    (address) => address.type === "SHIPPING"
  )
  const userBillingAddress = user?.address?.find(
    (address) => address.type === "INVOICING"
  )
  const userVerificationStatus = user?.profile?.verificationStatus
  const [activePage, setActivePage] = useState<null | PROFILE_PAGES>(null)
  const [previousPage, setPreviousPage] = useState<null | PROFILE_PAGES>(null)

  const complete_icon = "/images/icons/icon-circled-check.svg#base"
  const incomplete_icon = "/images/icons/icon-dot.svg#base"

  const menuItems = useMemo(
    () => [
      {
        label: "Summary",
        disabled: false,
        bannerText:
          user?.profile?.profileType === "PRINCIPAL"
            ? "This is a summary of your current rewards level and training progress."
            : "This is your real-time ranking. Climb the ranks by making sales, and be rewarded with leads.",
        icon: null,
        accessor: PROFILE_PAGES.CurrentRanking,
        hidden: !user?.profile?.isProfileActivated,
      },
      {
        label: "Edit Profile",
        disabled: false,
        bannerText: "Ensure your profile settings are up to date.",
        icon: !user?.profile?.isProfileActivated ? complete_icon : null,
        accessor: PROFILE_PAGES.EditProfile,
        hidden: false,
      },
      {
        label: "Verify Identity",
        disabled: false,
        bannerText: "Please verify your identity to receive your payouts.",
        icon: incomplete_icon,
        accessor: PROFILE_PAGES.VerifyIdentity,
        hidden: user?.profile?.verificationStatus === "SUCCESSFUL",
      },
      {
        label: "Password",
        disabled: false,
        bannerText: "Reset your password here or skip to continue.",
        icon: !user?.profile?.isProfileActivated ? complete_icon : null,
        accessor: PROFILE_PAGES.Password,
        hidden: false,
      },
      {
        label: "Banking Details",
        disabled: !user?.profile?.isProfileActivated
          ? user?.profile == null
          : false,
        bannerText:
          user?.profile?.profileType === "ASSOCIATE"
            ? "These are the banking details that your Principal will pay your payouts to."
            : "This is the bank account your rewards / commission will be paid into.",
        icon: !user?.profile?.isProfileActivated
          ? user?.bankingDetails
            ? complete_icon
            : incomplete_icon
          : null,
        accessor: PROFILE_PAGES.BankingDetails,
        hidden: false,
      },
      {
        label: "Shipping Address",
        disabled: !user?.profile?.isProfileActivated
          ? user?.bankingDetails == null
          : false,
        bannerText: userShippingAddress
          ? "Update your personal delivery address. We may send you some cool stuff in the future."
          : "Add your personal delivery address. We may send you some cool stuff in the future.",
        icon: !user?.profile?.isProfileActivated
          ? userShippingAddress
            ? complete_icon
            : incomplete_icon
          : null,
        accessor: PROFILE_PAGES.ShippingAddress,
        hidden: false,
      },
      {
        label: "Billing Address",
        disabled: !user?.profile?.isProfileActivated
          ? userShippingAddress == null
          : false,
        bannerText:
          user?.profile?.profileType === "ASSOCIATE"
            ? "This is the billing address that your Principal will use when paying you."
            : userBillingAddress
            ? "Update your billing address. This will be added to the monthly invoices."
            : "Add your billing address. This will be added to the monthly invoices.",
        icon: !user?.profile?.isProfileActivated
          ? userBillingAddress
            ? complete_icon
            : incomplete_icon
          : null,
        accessor: PROFILE_PAGES.BillingAddress,
        hidden: false,
      },
      {
        label: "Preferences",
        disabled: !user?.profile?.isProfileActivated
          ? userBillingAddress == null
          : false,
        bannerText: "Manage your preferences.",
        icon: !user?.profile?.isProfileActivated
          ? user?.preferences
            ? complete_icon
            : incomplete_icon
          : null,
        accessor: PROFILE_PAGES.Preferences,
        hidden: false,
      },
      {
        label: "Reseller Contract",
        disabled: !user?.profile?.isProfileActivated
          ? user?.preferences == null
          : false,
        bannerText: user?.profile?.contractSigned
          ? "Your profile is fully completed. You can review the policies below."
          : user?.profile?.profileType == "ASSOCIATE"
          ? "These are the terms between yourself and yoco. Please review, acknowledge and confirm to make this official."
          : "These is the contract between yourself and Yoco. Please read, acknowledge policy pdfs and sign the contract to make this official. After you sign, please check your email for the copy of contract",
        icon: !user?.profile?.isProfileActivated
          ? user?.profile?.contractSigned
            ? complete_icon
            : incomplete_icon
          : null,
        accessor: PROFILE_PAGES.ResellerContract,
        hidden: false,
      },
    ],
    [
      user?.bankingDetails,
      user?.preferences,
      user?.profile,
      userShippingAddress,
      userBillingAddress,
    ]
  )

  const currentMenuItem = menuItems.find((obj) => {
    return obj.accessor === activePage
  })

  const previousMenuItem = menuItems.find((obj) => {
    return obj.accessor === previousPage
  })

  useEffect(() => {
    if (!isMobile && activePage === null) {
      const index = user?.profile?.isProfileActivated
        ? PROFILE_PAGES.CurrentRanking
        : PROFILE_PAGES.EditProfile
      setActivePage(index)
    }
  }, [activePage, user?.profile?.isProfileActivated, isMobile])

  useEffect(() => {
    if (isMobile) {
      setActivePage(null)
    }
  }, [isMobile])

  const showMobileMenu = () => {
    setPreviousPage(activePage)
    setActivePage(null)
  }

  const onNavigate = (page: PROFILE_PAGES) => {
    setActivePage(page)
  }

  const updateActivePage = (page: PROFILE_PAGES) => {
    setActivePage(page)
  }

  const renderPage = (page: PROFILE_PAGES | null) => {
    switch (page) {
      case PROFILE_PAGES.CurrentRanking:
        return <CurrentRanking />
      case PROFILE_PAGES.EditProfile:
        return (
          <ProfileForm
            onComplete={() => {
              userVerificationStatus === "SUCCESSFUL"
                ? updateActivePage(PROFILE_PAGES.Password)
                : updateActivePage(PROFILE_PAGES.VerifyIdentity)
            }}
          />
        )
      case PROFILE_PAGES.VerifyIdentity:
        return (
          <IdentityVerificationForm
            onNext={() => updateActivePage(PROFILE_PAGES.Password)}
          />
        )
      case PROFILE_PAGES.Password:
        return (
          <PasswordForm
            onComplete={() => updateActivePage(PROFILE_PAGES.BankingDetails)}
          />
        )
      case PROFILE_PAGES.BankingDetails:
        return (
          <BankingDetailsForm
            onComplete={() => updateActivePage(PROFILE_PAGES.ShippingAddress)}
          />
        )
      case PROFILE_PAGES.ShippingAddress:
        return (
          <ShippingAddressForm
            onComplete={() => updateActivePage(PROFILE_PAGES.BillingAddress)}
          />
        )
      case PROFILE_PAGES.BillingAddress:
        return (
          <BillingAddressForm
            onComplete={() => updateActivePage(PROFILE_PAGES.Preferences)}
          />
        )
      case PROFILE_PAGES.Preferences:
        return (
          <PreferencesForm
            onComplete={() => updateActivePage(PROFILE_PAGES.ResellerContract)}
          />
        )
      case PROFILE_PAGES.ResellerContract:
        return <ResellerContractForm />
    }
  }
  return (
    <PageGuard>
      <Seo title="Profile" />
      <Header>
        <BreadcrumbNav>
          <h1>
            <span
              onClick={() => {
                showMobileMenu()
              }}
            >
              {" "}
              My Profile
            </span>
            {activePage !== null && (
              <>
                <span>{`>`}</span>
                <span>{currentMenuItem?.label}</span>
              </>
            )}
            {previousPage !== null && activePage === null && (
              <span className="previousPage">
                <span>{`>`}</span>
                <span>{previousMenuItem?.label}</span>
              </span>
            )}
          </h1>
        </BreadcrumbNav>
        <DesktopHeader>
          <PageHeader
            cypressTestId="my-profile-page-header"
            title="My Profile"
          />
        </DesktopHeader>
      </Header>
      {currentMenuItem !== null && currentMenuItem?.bannerText ? (
        <PageNotificationBanner>
          {currentMenuItem?.bannerText}
        </PageNotificationBanner>
      ) : null}

      <NavigationLayout
        title="Profile Navigation"
        subTitle={
          !user?.profile?.isProfileActivated
            ? "Update all outstanding info to get full access to your dashboard"
            : null
        }
        subTitleIcon={
          !user?.profile?.isProfileActivated ? incomplete_icon : null
        }
        items={menuItems}
        activeItem={activePage}
        onNavigate={onNavigate}
      >
        {renderPage(activePage)}
      </NavigationLayout>
    </PageGuard>
  )
}

export default ProfilePage
