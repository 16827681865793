import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import FormHeader from "./FormHeader"
import FormWrapper from "./FormWrapper"
import { useUser } from "../../hooks"
import { Button } from "../../../components"

const InfoText = styled.p`
  font-size: 13px;
  line-height: 16px;
  a {
    color: ${({ theme }) => theme.palette.orangePeel};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 16px;
    line-height: 20px;
  }
`

type Props = {
  onNext: () => void
}

const IdentityVerificationForm: React.FC<Props> = ({ onNext }) => {
  const { user } = useUser()
  return (
    <FormWrapper
      buttons={
        user?.profile?.verificationStatus === "NOT_STARTED" ? (
          <>
            <Button
              label="Start verification process"
              type="submit"
              color="#00a9e0"
              textColor="white"
              onClick={(e) => {
                e.preventDefault()
                navigate("/onfido/")
              }}
            />
            <Button
              label="Skip"
              color="#e1eae8"
              onClick={onNext}
              textColor="#222222"
            />
          </>
        ) : (
          <Button
            label="Next"
            color="#e1eae8"
            onClick={onNext}
            textColor="#222222"
          />
        )
      }
    >
      <div>
        <FormHeader>Verify your identity</FormHeader>
        {user?.profile?.verificationStatus === "NOT_STARTED" ? (
          <InfoText>
            Please verify your identity to receive your payouts. Please read
            <a
              href="https://www.yoco.com/za/articles/reseller-legal-explainer/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              this article{" "}
            </a>
            for information on why we need to verify our identity or email{" "}
            <a href="mailto:reseller@yoco.com">reseller@yoco.com</a> for any
            enquiries.
          </InfoText>
        ) : user?.profile?.verificationStatus === "PENDING" ? (
          <InfoText>
            Your identity verification is in progress. The process can take up
            to 48 hours. Please email
            <a href="mailto:reseller@yoco.com"> reseller@yoco.com </a> for any
            enquiries.
          </InfoText>
        ) : user?.profile?.verificationStatus === "FAILED" ? (
          <InfoText>
            Your identity verification has failed. A system administrator is
            looking into this. Please email
            <a href="mailto:reseller@yoco.com"> reseller@yoco.com </a> for any
            enquiries.
          </InfoText>
        ) : null}
      </div>
    </FormWrapper>
  )
}

export default IdentityVerificationForm
