import { Formik } from "formik"
import React, { useCallback, useMemo } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { Button, FormGrid, Input } from "../../../components"
import { formatMobileNumber } from "../../../utils/mobileNumber"
import { useUser, useUpdateAddress } from "../../hooks"
import { validatePhone, validateAddress } from "../../validationObjects"
import CountriesField from "./CountriesField"
import FormHeader from "./FormHeader"
import ProvincesField from "./ProvincesField"

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  .buttonContainer {
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 50%;
    }
  }
`

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name required"),
  lastName: Yup.string().required("Last name required"),
  companyName: Yup.string(),
  phoneNumber: validatePhone,
}).concat(validateAddress)

interface Props {
  onComplete?: () => void
}

const ShippingAddressForm: React.FC<Props> = ({ onComplete }) => {
  const updateShippingAddress = useUpdateAddress()
  const { user } = useUser()
  const shippingAddress = user?.address?.find(
    (address) => address.type === "SHIPPING"
  )

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        const success = await updateShippingAddress(values)
        setSubmitting(false)
        if (onComplete && success) {
          onComplete()
        }
      } catch (err) {
        console.warn(err)
      }
    },
    [updateShippingAddress, onComplete]
  )

  const initialValues = useMemo(() => {
    return {
      id: shippingAddress?.id ?? undefined,
      firstName: shippingAddress?.firstName ?? "",
      lastName: shippingAddress?.lastName ?? "",
      companyName: shippingAddress?.companyName ?? "",
      phoneNumber: shippingAddress
        ? formatMobileNumber(shippingAddress.phoneNumber).substring(1)
        : "",
      addressLine1: shippingAddress?.addressLine1 ?? "",
      addressLine2: shippingAddress?.addressLine2 ?? "",
      city: shippingAddress?.city ?? "",
      province: shippingAddress?.province ?? "",
      country: shippingAddress?.country ?? "",
      postalCode: shippingAddress?.postalCode ?? "",
      type: "SHIPPING",
    }
  }, [shippingAddress])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <FormHeader showCompletionIcon complete={!!shippingAddress}>
              Shipping Address
            </FormHeader>
            <FormGrid numRows={5}>
              <Input
                label="First Name *"
                placeholder="First Name"
                name="firstName"
              />
              <Input
                label="Last Name *"
                placeholder="Last Name"
                name="lastName"
              />
              <Input label="Company" placeholder="Company" name="companyName" />
              <Input
                label="Phone Number *"
                placeholder="Phone Number"
                name="phoneNumber"
                additionalLabel="+27"
              />
              <Input
                label="Number and Street Address *"
                placeholder="Number and Street Address"
                name="addressLine1"
              />
              <Input label="Suburb" placeholder="Suburb" name="addressLine2" />
              <Input label="City *" placeholder="City" name="city" />
              <CountriesField />
              <ProvincesField selectedCountry={values.country} />
              <Input
                label="Postal Code *"
                placeholder="Postal Code"
                name="postalCode"
              />
            </FormGrid>
          </div>
          <div className="buttonContainer">
            {dirty || !user?.profile?.isProfileActivated ? (
              <Button
                label="Save Changes"
                disabled={isSubmitting || !isValid || !dirty}
                loading={isSubmitting}
                type="submit"
                color="#00a9e0"
                textColor="white"
              />
            ) : (
              <Button
                label="Skip"
                disabled={isSubmitting}
                loading={isSubmitting}
                color="#e1eae8"
                onClick={onComplete}
                textColor="#222222"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ShippingAddressForm
