import { Formik } from "formik"
import React, { useCallback, useMemo } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import {
  Button,
  FormGrid,
  DropDownInput,
  GooglePlacesInput,
  MultiSelectInput,
} from "../../../components"
import { useUser, useUpdatePreferences, useGetLanguages } from "../../hooks"
import FormHeader from "./FormHeader"
import ShirtSizeField from "./ShirtSizeField"

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  .buttonContainer {
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 50%;
    }
  }

  .fullWidthInput {
    grid-column: span 1;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-column: span 2;
    }
  }

  .custom-scaled-input {
    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.md}) and (max-width: ${({ theme }) =>
        theme.breakpoints.lg}) {
      grid-column: span 2;
    }
  }

  .shirtSizes {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
`

const validationSchema = Yup.object().shape(
  {
    firstLanguage: Yup.string().required("Please select your first language"),
    secondLanguages: Yup.array().when("firstLanguage", {
      is: (firstLanguage: string) =>
        !firstLanguage || firstLanguage.length === 0,
      then: Yup.array().max(0, "Please select your first language"),
      otherwise: Yup.array(),
    }),
    salesAreas: Yup.array().min(1, "Please add at least one area tag"),
    shirtSize: Yup.string().required("Please choose your preferred shirt size"),
  },
  [["firstLanguage", "secondLanguages"]]
)

interface Props {
  onComplete?: () => void
}

const PreferencesForm: React.FC<Props> = ({ onComplete }) => {
  const updatePreferences = useUpdatePreferences()
  const [data, loading] = useGetLanguages()
  const { user } = useUser()

  const initialFormValues = useMemo(() => {
    return {
      firstLanguage: user?.preferences?.firstLanguage ?? "",
      secondLanguages: user?.preferences?.secondLanguages ?? [],
      salesAreas: user?.salesAreas ?? [],
      shirtSize: (user?.preferences?.shirtSize as Reseller.ShirtSize) ?? "",
    }
  }, [user])

  const onSubmit = useCallback(
    async (values: Reseller.Preferences, { setSubmitting }) => {
      try {
        updatePreferences(values)
        setSubmitting(false)
        if (onComplete) {
          onComplete()
        }
      } catch (err) {
        console.warn(err)
      }
    },
    [updatePreferences, onComplete]
  )

  const languages: Inputs.SelectOption[] =
    data?.languages?.map((language) => {
      const dialect =
        language.dialect.length > 0 ? ` (${language.dialect})` : ""
      return {
        label: `${language.languageName}${dialect}`,
        value: `${language.languageName}${dialect}`,
      }
    }) ?? []

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <FormHeader showCompletionIcon complete={!!user?.preferences}>
              Preferences
            </FormHeader>
            <FormGrid numRows={4} numSpanInputs={2}>
              <div className="custom-scaled-input">
                <DropDownInput
                  label="First Language *"
                  placeholder="Select a language"
                  options={languages}
                  name="firstLanguage"
                  loading={loading}
                />
              </div>
              <div className="custom-scaled-input">
                <MultiSelectInput
                  label="Second Language"
                  placeholder="Select a language"
                  options={languages}
                  excludedItem={values.firstLanguage}
                  name="secondLanguages"
                  loading={loading}
                  disabled={values.firstLanguage === "" ? true : false}
                  disabledPlaceholder="Select a first language"
                  emptyPlaceholder="No languages selected"
                />
              </div>
              <div className="fullWidthInput">
                <GooglePlacesInput
                  label="Your Preferred Sales Area *"
                  placeholder="Tag an area..."
                  name="salesAreas"
                />
              </div>
              <div className="fullWidthInput shirtSizes">
                <ShirtSizeField />
              </div>
            </FormGrid>
          </div>
          <div className="buttonContainer">
            {dirty || !user?.profile?.isProfileActivated ? (
              <Button
                label="Save Changes"
                disabled={isSubmitting || !isValid || !dirty}
                loading={isSubmitting}
                type="submit"
                color="#00a9e0"
                textColor="white"
              />
            ) : (
              <Button
                label="Skip"
                disabled={isSubmitting}
                loading={isSubmitting}
                color="#e1eae8"
                onClick={onComplete}
                textColor="#222222"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PreferencesForm
