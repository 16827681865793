import { Formik } from "formik"
import _ from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import {
  Button,
  FileUploadInput,
  Input,
  FormGrid,
  ProfileImage,
} from "../../../components"
import { formatMobileNumber } from "../../../utils/mobileNumber"
import { useUser, useUpdateProfile, UpdateProfilePayload } from "../../hooks"
import {
  validateEmail,
  validatePhone,
  validateIdentity,
  validateProfilePicture,
  validateFirstName,
  validateLastName,
} from "../../validationObjects"
import IdentificationInputGroup from "../IdentificationInputGroup"
import FormHeader from "./FormHeader"

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  form {
    margin: 0;
  }

  .button-container {
    width: 100%;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 50%;
    }
  }

  .full-width-input {
    grid-column: span 1;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      grid-column: span 2;
    }
  }
`

const ImageSection = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: flex-end;
  grid-column: span 1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-column: span 2;
  }

  .image-container {
    background-color: ${({ theme }) => theme.palette.mystic};
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    height: 100%;
    max-height: 64px;
    width: 100%;
    max-width: 64px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      border-radius: 18px;
      max-height: 86px;
      max-width: 86px;
    }

    img {
      object-fit: cover;
      object-position: center;
      border-radius: 11px;

      @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        border-radius: 18px;
      }
    }
  }
`

const validationSchema = Yup.object({
  firstName: validateFirstName,
  lastName: validateLastName,
  email: validateEmail,
  mobileNumber: validatePhone,
  profilePicture: validateProfilePicture,
}).concat(validateIdentity)

interface Props {
  onComplete?: () => void
}

const ProfileForm: React.FC<Props> = ({ onComplete }) => {
  const { user } = useUser()
  const updateProfile = useUpdateProfile()
  const [deletingProfilePicture, setDeletingProfilePicture] = useState(false)

  const initialFormValues = useMemo(() => {
    return {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      email: user?.email ?? "",
      mobileNumber: user
        ? formatMobileNumber(user.mobileNumber).substring(1)
        : "",
      idNumber: user?.profile?.idNumber ?? "",
      passportNumber: user?.profile?.passportNumber ?? "",
      profilePicture: undefined,
      deletingProfilePicture: false,
    }
  }, [user])

  const onSubmit = useCallback(
    async (values: UpdateProfilePayload, { setSubmitting }) => {
      try {
        const changed_values = _.pickBy(
          values,
          (v, k) =>
            !_.isEqual(initialFormValues[k as keyof UpdateProfilePayload], v)
        )

        changed_values.deletingProfilePicture = deletingProfilePicture
        const success = await updateProfile(changed_values)
        setSubmitting(false)
        if (onComplete && success) {
          onComplete()
        }
      } catch (err) {
        console.warn(err)
      }
    },
    [updateProfile, onComplete, deletingProfilePicture, initialFormValues]
  )

  const handleProfilePictureDelete = useCallback(() => {
    setDeletingProfilePicture(true)
  }, [])

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, values, errors, isValid, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <FormHeader complete={!!user?.profile} showCompletionIcon>
            Edit Profile
          </FormHeader>
          <FormGrid numRows={4} numSpanInputs={2}>
            <ImageSection>
              <div className="image-container">
                <ProfileImage
                  src={
                    deletingProfilePicture && !values?.profilePicture
                      ? "/images/icons/icon-default-profile.svg"
                      : values.profilePicture && !errors.profilePicture
                      ? URL.createObjectURL(values.profilePicture)
                      : user?.profile?.profilePicture
                      ? user?.profile.profilePicture
                      : "/images/icons/icon-default-profile.svg"
                  }
                  alt="Profile Image"
                  width="86px"
                  height="86px"
                />
              </div>
              <FileUploadInput
                name="profilePicture"
                label="Upload a new image"
                expectedFileType="image"
                disabled={isSubmitting}
                hasDelete
                onDelete={handleProfilePictureDelete}
                padding="0px 0px 0px 10px"
                isFileSet={
                  !!values?.profilePicture || !!user?.profile?.profilePicture
                }
              />
            </ImageSection>
            <Input
              label="First Name *"
              placeholder="First Name"
              name="firstName"
            />
            <Input
              label="Last Name *"
              placeholder="Last Name"
              name="lastName"
            />
            <Input
              label="Email Address *"
              placeholder="Email"
              name="email"
              type="email"
              disabled
            />
            <Input
              label="Phone Number *"
              placeholder="Phone Number"
              name="mobileNumber"
              additionalLabel="+27"
            />
            <div className="full-width-input">
              <IdentificationInputGroup
                orientation="horizontal"
                label="Identification *"
                idAdded={!!values.idNumber}
                passportAdded={!!values.passportNumber}
              />
            </div>
          </FormGrid>
          <div className="button-container">
            {dirty ||
            !user?.profile?.isProfileActivated ||
            deletingProfilePicture ? (
              <Button
                label="Save Changes"
                disabled={
                  !deletingProfilePicture &&
                  (isSubmitting || !isValid || !dirty)
                }
                loading={isSubmitting}
                type="submit"
                color="#00a9e0"
                textColor="white"
              />
            ) : (
              <Button
                label="Skip"
                disabled={isSubmitting}
                loading={isSubmitting}
                color="#e1eae8"
                onClick={onComplete}
                textColor="#222222"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ProfileForm
