import React from "react"
import { DropDownInput } from "../../../components"
import { useGetCountries } from "../../hooks"

const CountriesField: React.FC = () => {
  const [data, loading] = useGetCountries()
  const countries =
    data?.countries?.map((country) => ({
      label: country.name,
      value: country.name,
    })) ?? []

  return (
    <DropDownInput
      placeholder="Country"
      name="country"
      label="Country *"
      options={countries}
      loading={loading}
    />
  )
}

export default CountriesField
