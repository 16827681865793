import { Formik } from "formik"
import React from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { Button, FormGrid, Password } from "../../../components"
import { useChangePassword } from "../../hooks"
import { validateNewPassword } from "../../validationObjects"
import FormHeader from "./FormHeader"

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  .buttonContainer {
    width: 100%;
    padding-bottom: 20px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 50%;
      padding-bottom: 0;
    }
  }
`

const validationSchema = Yup.object().shape(
  {
    oldPassword: Yup.string().required("Old password required"),
    password: Yup.string()
      .when("oldPassword", {
        is: (oldPassword: string) => !oldPassword || oldPassword.length === 0,
        then: Yup.string().max(
          0,
          "Please enter your password above before confirming it here"
        ),
        otherwise: validateNewPassword,
      })
      .test(
        "passwords-match",
        "Your new password cannot be the same as your old password",
        function (value) {
          return this.parent.oldPassword !== value
        }
      ),
  },
  [["oldPassword", "password"]]
)

interface Props {
  onComplete?: () => void
}

const PasswordForm: React.FC<Props> = ({ onComplete }) => {
  const changePassword = useChangePassword()

  return (
    <Formik
      initialValues={{
        oldPassword: "",
        password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const success = await changePassword(values)
          setSubmitting(false)
          if (onComplete && success) {
            onComplete()
          }
        } catch (err) {
          console.warn(err)
        }
      }}
    >
      {({ handleSubmit, isSubmitting, dirty, isValid }) => (
        <Form onSubmit={handleSubmit}>
          <div>
            <FormHeader helpText="If you would like to change your password">
              Password
            </FormHeader>
            <FormGrid singleInputRows numRows={2}>
              <Password
                label="Old Password *"
                placeholder="Old Password"
                name="oldPassword"
              />
              <Password
                label="New Password *"
                placeholder="New Password"
                name="password"
                helpText="New password must be a minimum of 8 characters"
              />
            </FormGrid>
          </div>
          <div className="buttonContainer">
            {dirty ? (
              <Button
                label="Change Password"
                disabled={isSubmitting || !isValid || !dirty}
                loading={isSubmitting}
                type="submit"
                color="#00a9e0"
                textColor="white"
              />
            ) : (
              <Button
                label="Skip"
                disabled={isSubmitting}
                loading={isSubmitting}
                color="#e1eae8"
                onClick={onComplete}
                textColor="#222222"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PasswordForm
