import { Formik } from "formik"
import React, { useState } from "react"
import styled from "styled-components"
import * as Yup from "yup"

import { Modal, CheckboxInput, DocumentCard, Button } from "../../../components"
import {
  useAcceptAssociateTerms,
  useGetDocusignSigningUrl,
  useGetPolicyDocuments,
  useUser,
} from "../../hooks"
import ContractCard from "./ContractCard"
import FormHeader from "./FormHeader"

const ContractModal = styled.iframe`
  width: 60vw;
  height: 70vh;
`

const Form = styled.form`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, min-content);
  column-gap: 20px;
  row-gap: 20px;
  margin: 0;
  position: relative;

  #header {
    grid-column: span 2;

    p {
      font-size: 13px;
      line-height: 16px;
    }
  }

  .readContractContainer {
    grid-column: span 2;
    margin: 20px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
  }

  .contract-container {
    grid-column: span 2;
  }

  .document-container {
    grid-column: span 2;
    background-color: ${({ theme }) => theme.palette.porcelain};
    padding: 20px;
    border-radius: 20px;

    .agreements {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .buttonContainer {
    grid-row-end: -1;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-rows: repeat(4, min-content);
    row-gap: 40px;

    #header p {
      font-size: 16px;
      line-height: 20px;
    }
  }
`

const validationSchema = Yup.object().shape(
  {
    readContract: Yup.bool().required(
      "Please read the documents above before signing the contract"
    ),
  },
  []
)

const ResellerContractForm: React.FC = () => {
  const { user } = useUser()
  const acceptAssociateTerms = useAcceptAssociateTerms()
  const [getSigningUrl, loading] = useGetDocusignSigningUrl()
  const [modalShowing, setModalShowing] = useState(false)
  const [targetDocument, setTargetDocument] = useState<string>()
  const policyDocumentData = useGetPolicyDocuments()

  const updateTargetDocument = (url: string) => {
    setTargetDocument(url)
    setModalShowing(true)
  }

  const contractSigned = user?.profile?.contractSigned
  const profileType = user?.profile?.profileType

  const getAssociateConfirmation = () => {
    try {
      acceptAssociateTerms()
    } catch (err) {
      console.warn(err)
    }
  }

  return (
    <>
      <Modal open={modalShowing} onClose={() => setModalShowing(false)}>
        {targetDocument ? (
          <ContractModal
            src={targetDocument}
            title="Your Yoco reseller contract"
          />
        ) : null}
      </Modal>
      <Formik
        initialValues={{
          readContract: contractSigned,
        }}
        validationSchema={validationSchema}
        onSubmit={
          profileType !== "ASSOCIATE" ? getSigningUrl : getAssociateConfirmation
        }
      >
        {({ handleSubmit, isValid, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div id="header">
              {profileType === "ASSOCIATE" ? (
                <>
                  <FormHeader showCompletionIcon complete={contractSigned}>
                    Let&apos;s make this official. Review the document below.
                  </FormHeader>

                  <p>
                    First review the document below and check the box before
                    proceeding to confirm your profile.
                  </p>
                </>
              ) : (
                <>
                  <FormHeader showCompletionIcon complete={contractSigned}>
                    Let&apos;s make this official. Read and sign the documents
                    below.
                  </FormHeader>

                  <p>
                    First review the agreements below and check the box before
                    proceeding to virtually sign the Reseller contract.
                  </p>
                </>
              )}
            </div>

            <div className="document-container">
              <div className="agreements">
                {!policyDocumentData.loading &&
                  policyDocumentData.policyDocumentData?.map(
                    (policyDocument, index) => {
                      return (
                        <DocumentCard
                          key={`policy-document-${index}`}
                          heading={policyDocument.name}
                          onClick={() =>
                            updateTargetDocument(policyDocument.url)
                          }
                        />
                      )
                    }
                  )}
              </div>
              <div className="readContractContainer">
                <CheckboxInput
                  id="contract"
                  readOnly={contractSigned}
                  name="readContract"
                  fontSize="14px"
                >
                  {profileType === "ASSOCIATE" ? (
                    <span>
                      I confirm that I am a Reseller acting on behalf of{" "}
                      {user?.profile?.principalFullName}
                    </span>
                  ) : (
                    <span>
                      I acknowledge, agree and consent to the policies above.
                    </span>
                  )}
                </CheckboxInput>
              </div>
            </div>
            {profileType !== "ASSOCIATE" ? (
              <div className="contract-container">
                <ContractCard
                  heading="Reseller contract"
                  disabled={!(dirty && isValid)}
                  loading={loading}
                  signed={contractSigned}
                />
              </div>
            ) : (
              <div className="buttonContainer">
                <Button
                  label={
                    user?.profile?.contractSigned
                      ? "Profile Confirmed"
                      : "Confirm Profile"
                  }
                  type="submit"
                  color="#00a9e0"
                  textColor="white"
                  disabled={user?.profile?.contractSigned}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ResellerContractForm
