import React from "react"

import { DropDownInput } from "../../../components"
import { useGetBankAccountTypes } from "../../hooks"

const BankAccountTypeField: React.FC = () => {
  const [data, loading] = useGetBankAccountTypes()
  const bankAccountType: Inputs.SelectOption[] =
    data?.bankAccounts?.map((bankAccount) => ({
      label: bankAccount.label,
      value: bankAccount.value,
    })) ?? []

  return (
    <DropDownInput
      placeholder="Select account type"
      name="accountType"
      label="Account Type *"
      options={bankAccountType}
      loading={loading}
    />
  )
}

export default BankAccountTypeField
