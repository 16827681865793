import React from "react"

import { RadioField } from "../../../components"
import { useGetShirtSizes } from "../../hooks"

const ShirtSizeField: React.FC = () => {
  const [data, loading] = useGetShirtSizes()

  const shirtSizes =
    data?.shirtSizes?.map((shirtSize) => ({
      label: shirtSize.name,
      fullText: shirtSize.label,
      value: shirtSize.value,
    })) ?? []

  return (
    <RadioField
      name="shirtSize"
      label="Your preferred shirt size *"
      options={shirtSizes}
      loading={loading}
    />
  )
}

export default ShirtSizeField
