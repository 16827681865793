import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"

import { Loader, Counter, ProfileImage } from "../../../components"
import { useTrainingModules } from "../../../training/hooks"
import {
  useGetGamificationProgress,
  useGetRanking,
  useUser,
  useGetSiteUnderMaintenance,
} from "../../hooks"
import FormHeader from "./FormHeader"

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  hr {
    margin: 20px 0;
    background-color: ${({ theme }) => theme.palette.opal};
  }
`

const RankingDetails = styled.div`
  .ranking-details-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }

  .ranking-details-row:not(:last-child) {
    margin-bottom: 20px;
  }

  .user-profile {
    display: flex;
    align-items: center;
    gap: 20px;

    img {
      background-color: #e1eae8;
      border-radius: 100%;
      width: 60px;
      height: 60px;
      object-fit: cover;
      object-position: center;
    }

    p {
      margin: 0;
      font-size: 17px;
      font-family: ${({ theme }) => theme.fonts.title};
      font-weight: 600;
    }
  }

  .current-level {
    p {
      margin: 0;
      font-size: 14px;
      line-height: 22px;
      color: ${({ theme }) => theme.palette.mineShaft};

      span {
        color: ${({ theme }) => theme.palette.outrageousOrange};
      }
    }

    p:last-child {
      font-size: 12px;
    }
  }
`

const RankingCountSummary = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
`

const TrainingModules = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  .training-heading {
    font-size: 20px;
  }

  .training-completed {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 30px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

const CurrentRanking: React.FC = () => {
  const { siteUnderMaintenance } = useGetSiteUnderMaintenance()

  const { user } = useUser()
  const trainingModules = useTrainingModules()
  const { gamificationData } = useGetGamificationProgress()

  const [isPrincipalUser, setIsPrincipalUser] = useState(true)
  const [rankingData, loading] = useGetRanking(isPrincipalUser)

  useEffect(() => {
    if (user?.profile?.profileType !== "PRINCIPAL") {
      setIsPrincipalUser(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile?.profileType])

  const trainingSummary = useMemo(
    () =>
      trainingModules.map((module) => {
        const moduleCompletedMaterialCount =
          user?.completedTrainingMaterial?.filter(
            (material) => material.material.module.id == module._uid
          ).length ?? 0
        return {
          title: module.title,
          totalMaterialCount:
            (module.guides?.length ?? 0) + (module.videos?.length ?? 0),
          completedMaterialCount: moduleCompletedMaterialCount,
        }
      }),
    [trainingModules, user]
  )

  const currentLevelIndex = useMemo(() => {
    const tempIndex = gamificationData?.badges?.findIndex(
      (badge) => badge.currentBadge === true
    )

    //In case user has no commission level, default to index 0 which is level 1
    if (tempIndex === -1) {
      return 0
    }
    return tempIndex
  }, [gamificationData])

  const rewardLevelPercentage = useCallback(
    (index: number) => {
      return gamificationData?.badges?.[index]?.percentage
    },
    [gamificationData]
  )

  const renderRanking = useCallback(() => {
    if (!loading) {
      let globalRank: Reseller.IndividualRank | undefined
      let provincialRank: Reseller.IndividualRank | undefined

      rankingData?.forEach((ranking) => {
        if (ranking?.scopeType === "GLOBAL") {
          globalRank = ranking
        } else if (ranking?.scopeType === "PROVINCIAL") {
          provincialRank = ranking
        }
      })

      return (
        <RankingCountSummary>
          <Counter
            count={globalRank?.rank}
            description={
              globalRank === undefined ? "Not available" : "country wide"
            }
            sizeVariant="large"
            tooltipText={
              provincialRank === undefined
                ? "Activate merchants in order to be ranked"
                : "Activate more merchants to improve your rank"
            }
            tooltipOffset={180}
          />
          <Counter
            count={provincialRank?.rank}
            description={
              provincialRank === undefined
                ? "Not available"
                : `in your province`
            }
            tooltipText={
              provincialRank === undefined
                ? "Activate merchants in order to be ranked"
                : "Activate more merchants to improve your rank"
            }
            tooltipOffset={100}
          />
        </RankingCountSummary>
      )
    } else {
      return <Loader />
    }
  }, [rankingData, loading])

  return (
    <Container>
      <FormHeader>Summary</FormHeader>
      <RankingDetails>
        <div className="ranking-details-row">
          {!siteUnderMaintenance && !isPrincipalUser ? renderRanking() : null}
        </div>
        <div className="ranking-details-row">
          <div className="user-profile">
            <ProfileImage
              src={
                user?.profile?.profilePicture
                  ? user?.profile?.profilePicture
                  : "/images/icons/icon-default-profile.svg"
              }
              alt={user ? `${user.firstName}'s user icon` : "Default user icon"}
            />
            <div>
              <p>{`${user?.firstName} ${user?.lastName}`}</p>
            </div>
          </div>
          {!siteUnderMaintenance ? (
            <div className="current-level">
              <p>
                This month&apos;s sales level:{" "}
                <b>{gamificationData?.badges?.[currentLevelIndex]?.name}</b>
              </p>
              {gamificationData?.badges?.[currentLevelIndex + 1] &&
              gamificationData?.badges?.[currentLevelIndex]?.maxMerchants ? (
                <p>
                  <span>
                    {gamificationData?.badges?.[currentLevelIndex + 1]
                      ?.minMerchants -
                      gamificationData?.activatedMerchants}{" "}
                    sales to unlock{" "}
                    {gamificationData?.badges?.[currentLevelIndex + 1]?.name}
                  </span>{" "}
                  and earn {rewardLevelPercentage(currentLevelIndex + 1)}% of
                  TPV
                </p>
              ) : (
                <>
                  <p>
                    Congratulations, you&apos;ve reached{" "}
                    {gamificationData?.badges?.[currentLevelIndex]?.name} sales
                    level!
                  </p>
                  <p>
                    You are earning a TPV of{" "}
                    {rewardLevelPercentage(currentLevelIndex)}%
                  </p>
                </>
              )}
            </div>
          ) : null}
        </div>
      </RankingDetails>
      <hr />

      <TrainingModules>
        <h3 className="training-heading">Training modules completed</h3>
        <div className="training-completed">
          {trainingSummary?.map((module, index) => (
            <Counter
              key={`training-ratio-${index}`}
              count={module.completedMaterialCount}
              total={module.totalMaterialCount}
              showZero={true}
              description={module.title}
              tooltipText="Complete all training modules to unlock more features"
              tooltipOffset={280}
            />
          ))}
        </div>
      </TrainingModules>
    </Container>
  )
}

export default CurrentRanking
