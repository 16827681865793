import React from "react"
import { DropDownInput } from "../../../components"
import { useGetCountries } from "../../hooks"

interface Props {
  selectedCountry: string
}

const ProvincesField: React.FC<Props> = ({ selectedCountry = "" }) => {
  const [data, loading] = useGetCountries()
  const country =
    data?.countries?.filter((country: { name: string }) => {
      return country.name === selectedCountry
    }) ?? []

  const provinces =
    country[0]?.provinces?.map((province: any) => ({
      label: province.name,
      value: province.name,
    })) ?? []

  return (
    <DropDownInput
      placeholder="Province"
      name="province"
      label="Province *"
      options={provinces}
      loading={loading}
      disabled={!selectedCountry}
    />
  )
}

export default ProvincesField
