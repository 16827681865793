import React from "react"
import styled from "styled-components"

const FormElement = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;

  .button-container {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
      flex-direction: row;
      > * {
        flex: 1;
        max-width: 50%;
      }

      width: 100%;
      padding-bottom: 0;
    }
  }
`

type Props = {
  className?: string
  buttons?: React.ReactNode
}

const Form: React.FC<Props> = ({ className, buttons, children }) => {
  return (
    <FormElement className={className}>
      {children}
      <div className="button-container">{buttons || null}</div>
    </FormElement>
  )
}

export default Form
