import { useField } from "formik"
import React, { useCallback, useMemo } from "react"

import { DropDownInput } from "../../../components"
import { useGetBanks } from "../../hooks"

const BankNameField: React.FC = () => {
  const [data, loading] = useGetBanks()
  const [field, meta, helpers] = useField("branchCode") // eslint-disable-line

  const handleBankChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (data) {
        const result = data.banks.find(
          (bank) => bank.bankName === event.target.value
        )
        helpers.setValue(result?.branchCode ?? "")
      }
    },
    [data, helpers]
  )

  const banks = useMemo(() => {
    const banks: Inputs.SelectOption[] = []

    if (!loading) {
      data.banks.map((bank) => {
        banks.push({ label: bank.bankName, value: bank.bankName })
      })
    }

    return banks
  }, [data, loading])

  return (
    <DropDownInput
      placeholder="Select bank"
      name="bankName"
      label="Bank Name *"
      options={banks}
      loading={loading}
      onChange={handleBankChange}
    />
  )
}

export default BankNameField
