import * as React from "react"
import styled from "styled-components"

import Arrow from "./Arrow"

const Container = styled.div`
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: grid;
    grid-template-columns: minmax(0, 250px) 1fr;
    gap: 20px;
  }
`

const Navigation = styled.div<{ hasContent: boolean }>`
  background-color: ${({ theme }) => theme.palette.mineShaft};
  color: ${({ theme }) => theme.palette.white};
  display: ${({ hasContent }) => (hasContent ? "none" : null)};
  border-radius: 15px;
  padding: 30px 0px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }

  h3 {
    padding: 0px 25px;
    font-size: 10px;
    font-family: ${({ theme }) => theme.fonts.title};
    color: ${({ theme }) => theme.palette.mystic};
    font-weight: normal;
    letter-spacing: 0;
    line-height: 14px;
    text-transform: uppercase;
  }

  .links {
    padding: 0px 10px;
  }
`

const SubTitleWrapper = styled.div`
  padding: 0px 25px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 10px;
    line-height: 15px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    max-width: 180px;
  }
`

const NavLink = styled.div`
  padding: 0px 15px;
  margin: 5px 0;
  border-radius: 10px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  height: 50px;
  flex-direction: row;
  justify-content: space-between;
  &.active,
  &:hover {
    cursor: pointer;
    background-color: #484848;
  }
  &.disabled {
    background-color: transparent;
    cursor: default;
    span {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  div {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  span {
    margin-bottom: -3px;
  }
  img {
    margin-bottom: 0px;
  }
  .arrow {
    margin-right: 15px;
  }
  .icon {
    align-self: center;
  }
`
const IconWrapper = styled.div`
  width: 20px;
  text-align: center;
`

const Content = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border-radius: 15px;
  padding: 20px 15px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 30px 36px;
  }
`

interface NavigationItem {
  label: string
  disabled: boolean
  icon?: string | null
  hidden: boolean
}

type Props = {
  title: string
  subTitle?: string | null
  subTitleIcon?: string | null
  items: Array<NavigationItem>
  activeItem: number | null
  onNavigate: (index: number) => void
  children?: React.ReactNode
}

const NavigationLayout: React.FC<Props> = ({
  title,
  subTitle,
  subTitleIcon,
  items,
  activeItem,
  onNavigate,
  children,
}) => {
  const onLinkClicked = (index: number) => {
    if (items[index].disabled) return
    onNavigate(index)
  }

  const renderMenuItem = (item: NavigationItem, index: number) => {
    if (!item.hidden) {
      return (
        <NavLink
          key={index}
          onClick={() => {
            onLinkClicked(index)
          }}
          className={`
        ${index === activeItem ? "active" : ""}
        ${item.disabled ? "disabled" : ""}
        `}
        >
          <div>
            {index === activeItem && (
              <div className="arrow">
                <Arrow />
              </div>
            )}
            <span>{item.label}</span>
          </div>
          {item.icon && (
            <IconWrapper>
              <svg className="icon" width={18} height={18}>
                <use href={item.icon} />
              </svg>
            </IconWrapper>
          )}
        </NavLink>
      )
    }
  }

  return (
    <Container>
      <Navigation hasContent={activeItem !== null}>
        <h3>{title}</h3>
        {subTitle && (
          <SubTitleWrapper>
            <p>{subTitle}</p>
            {subTitleIcon && (
              <IconWrapper>
                <svg className="icon" width={18} height={18}>
                  <use href={subTitleIcon} />
                </svg>
              </IconWrapper>
            )}
          </SubTitleWrapper>
        )}

        <div className="links">
          {items?.map((item, index) => renderMenuItem(item, index))}
        </div>
      </Navigation>

      {activeItem !== null && <Content>{children}</Content>}
    </Container>
  )
}

export default NavigationLayout
