import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  h3 {
    font-weight: 500;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
  }

  p {
    font-size: 10px;
    margin: 0;
    color: ${({ theme }) => theme.palette.doveGrey};
    margin-left: 15px;
  }

  svg {
    display: block;
    margin-left: 15px;
    margin-bottom: 3px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    h3 {
      font-size: 20px;
      line-height: 32px;
    }

    p {
      font-size: 12px;
    }

    img {
      display: none;
    }
  }
`

type Props = {
  complete?: boolean
  showCompletionIcon?: boolean
  helpText?: string
}

const FormHeader: React.FC<Props> = ({
  complete,
  children,
  helpText,
  showCompletionIcon,
}): JSX.Element => {
  const complete_icon = "/images/icons/icon-circled-check.svg#base"
  const incomplete_icon = "/images/icons/icon-dot.svg#base"

  return (
    <Wrapper>
      <h3>{children}</h3>
      {helpText ? <p>{helpText}</p> : null}
      {showCompletionIcon ? (
        <svg className="icon" width={18} height={18}>
          <use href={complete ? complete_icon : incomplete_icon} />
        </svg>
      ) : null}
    </Wrapper>
  )
}

export default FormHeader
